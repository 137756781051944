<template>
	<div class="full-height">
		<h6>{{ program.name }}</h6>

		<div class="">
			<div class="justify-space-between">
				<div class="flex-1 bg-white mr-10">
					<div class="pa-10">
						<h6 class="under-line-identify">신청 구분</h6>
						<table class="table th-left td-left">
							<col width="120px">
							<col width="auto">
							<col width="120px">
							<col width="auto">
							<tbody>
							<tr>
								<th>소속 영업단 <span class="color-red">*</span></th>
								<td colspan="3">
									<div class="justify-start">

										<div class="flex-column justify-center pa-5-10 box mr-10 bg-eee font-weight-bold">{{ item.upper_name ? item.upper_name : upper_name }}</div>

										<button
											v-if="is_confirm"
											class="bg-identify pa-5-10 vertical-middle"
											@click="upperIdx = ''; getAgencyList()"
										>영업단 변경</button>
									</div>
								</td>
							</tr>
							<tr>
								<th>아이디</th>
								<td>{{ item.id }}</td>
								<th>비밀번호</th>
								<td class="text-right">
									<div class="justify-space-between">
										<span class=""></span>
										<button
											class="bg-identify pa-5-10"
											@click="isPassword"
										>임시 비밀번호 발급</button>
									</div>
								</td>
							</tr>
							<tr>
								<th>심사여부</th>
								<td>
									<div :class="'color-' + state_name.confirm.color">{{ state_name.confirm.name }}</div>
								</td>
								<th>계정상태</th>
								<td>
									<div
										class="justify-space-between"
									>
										<div class="flex-column justify-center" :class="'color-' + state_name.state.color">{{ state_name.state.name }}</div>
										<button
											v-if="item.confirm"
											class="bg-identify pa-5-10 "
											@click="isStatus"
										>계정 상태 변경</button>
									</div>
								</td>
							</tr>
							<tr>
								<th>나이스 인증</th>
								<td>
									<div :class="'color-' + state_name.nice.color">{{ state_name.nice.name }}</div>
								</td>
								<th>등록일자</th>
								<td>{{ item.date }}</td>
							</tr>


							<tr>
								<th>수기결제 MID</th>
								<td class="">
									<input
										v-model="item.manualPaymentId"
										class="input-box full-width"
										placeholder="수기결제 MID"
										max="50"
									/></td>

								<th>비밀번호 입력</th>
								<td class="">
									<input
										v-model="item.manualPaymentPassword"
										type="password"
										class="input-box full-width"
										placeholder="수기결제 계정 비밀번호"
										max="50"
									/>
								</td>
							</tr>

							<tr>
								<th>빌키결제 MID</th>
								<td class="">
									<input
										v-model="item.billKeyPaymentId"
										class="input-box full-width"
										placeholder="빌키결제 MID"
										max="50"
									/>
								</td>

								<th>비밀번호 입력</th>
								<td class="">
									<input
										v-model="item.billKeyPaymentPassword"
										type="password"
										class="input-box full-width"
										placeholder="빌키결제 계정 비밀번호"
										max="50"
									/>
								</td>
							</tr>
							</tbody>
						</table>
					</div>
				</div>
				<div class="flex-1">
					<div class=" bg-white pa-10">
					<h6 class=" under-line-identify">대표자 정보</h6>

					<table class="table th-left td-left">

						<col width="120px" />
						<col width="width-50-120" />
						<col width="120px" />
						<col width="width-50-120" />

						<tbody>
						<tr>
							<th>대표자명 <span class="color-red">*</span></th>
							<td>
								<input
									v-model="item.name"
									class="box pa-5-10 mr-10"
									placeholder="대표자명을 입력하세요"
								/>
							</td>
							<th>휴대폰 <span class="color-red">*</span></th>
							<td>
								<input
									v-model="item.hp"
									class="box pa-5-10 mr-10"
									placeholder="대표자 휴대폰번호를 입력하세요"
								/>
							</td>
						</tr>
						<tr>
							<th>자택주소 <span class="color-red">*</span></th>
							<td colspan="3">

								<input
									v-model="item.homeZipCode"

									class="input-box-inline mr-10"
									type="text" placeholder="우편번호"
									readonly

									@click="daumPost('home')"
								>

								<button
									class="box pa-5-10 bg-identify"
									@click="daumPost('home')"
								>주소 검색</button>

								<input
									v-model="item.homeAddress"

									class="input-box  mt-10" type="text" placeholder="주소"
									readonly

									@click="daumPost('home')"
								>

								<input
									v-model="item.homeAddressDetail"

									class="input-box  mt-10" type="text" placeholder="상세주소를 입력하세요."
									maxlength="50"
								>
							</td>
						</tr>
						</tbody>
					</table>
					</div>
				</div>
			</div>

			<div class="mt-10 justify-space-between">
				<div class="flex-1 mr-10 ">
					<div class="pa-10 bg-white">
						<div
							class="under-line-identify justify-space-between"
						>
							<h6>서비스 이용 조건</h6>
							<span class="flex-column justify-end size-px-12">수수료는 소수점 두자리, 부가세는 별도입니다.</span>
						</div>

						<table class="table th-left td-left">
							<col width="120px" />
							<col width="auto" />
							<tbody>
							<tr>
								<th>서비스 수수료 <span class="color-red">*</span></th>
								<td>
									<div class="justify-space-between">
										<div class="flex-1">
											영업단 수익
											<input v-model="fee2" class="input-box-inline bg-identify text-right width-50px color-eee" readonly /> %
										</div>
										<div class="flex-2">
											수수료 할인
											<input
												v-model="item.fee" type="number" class="input-box-inline width-50px text-right" @change="checkFee()"
												:rules="[rules.demical(item, 'fee', { min: 2, max: 2})]"
											/> %
											(최대 {{ maxAvailableFee }} %)
										</div>
									</div>
								</td>
							</tr>
							<tr>
								<th>한도 설정  <span class="color-red">*</span></th>
								<td>
									<input
										type="number"
										v-model="item.oneLimit"
										placeholder="1회 한도"
										class="input-box-inline text-right "
										:rules="[rules.max(item, 'limit_first', 5)]"
									/> 만원
									<input
										type="number"
										v-model="item.monthlyLimit"
										placeholder="월 한도"
										class="input-box-inline text-right"
										:rules="[rules.max(item, 'limit_month', 6)]"
									/> 만원
									<input
										type="number"
										v-model="item.yearlyLimit"
										placeholder="연 한도"
										class="input-box-inline text-right"
										:rules="[rules.max(item, 'limit_year', 7)]"
									/> 만원
								</td>
							</tr>
							<tr>
								<th>정산주기 <span class="color-red">*</span></th>
								<td>
									<label

										v-for="code in codes.P03"
										:key="code.TOTAL_CODE"

										class="cont-radio mr-10 pa-5-10"
										:class="item.settlementCycle == code.TOTAL_CODE ? 'bg-identify' : 'bg-bbb'"
									>
										<input
											v-show="false"
											v-model="item.settlementCycle"
											type="radio"
											:value="code.TOTAL_CODE"
											class="box"
										/>
										{{ code.code_name }}
									</label>
								</td>
							</tr>
							</tbody>
						</table>
					</div>
					<div class="pa-10 bg-white mt-10">
						<h6>매출알림 서비스</h6>
						<table class="table th-left td-left top-line-identify">
							<col width="120px" />
							<col width="width-50-120" />
							<col width="120px" />
							<col width="width-50-120" />
							<tbody>
							<tr>
								<th>여신사 아이디</th>
								<td>
									<input
										v-model="item.cardId"
										class="input-box full-width"
										:rules="[rules.max(item, 'bank_name', 25)]"
										placeholder="여신사 아이디를 입력하세요"
									/>
								</td>
								<th>여신사 비밀번호</th>
								<td>
									<input
										v-model="item.cardPassword"
										type="password"
										class="input-box full-width"
										:rules="[rules.max(item, 'bank_name', 25)]"
										placeholder="여신사 비밀번호를 입력하세요"
									/>
								</td>
							</tr>
							<tr>
								<th>배달의 민족 아이디</th>
								<td>
									<input
										v-model="item.baeminId"
										class="input-box full-width"
										:rules="[rules.max(item, 'baeminId', 25)]"
										placeholder="배달의 민족 아이디를 입력하세요"
									/>
								</td>
								<th>배달의 민족 비밀번호</th>
								<td>
									<input
										v-model="item.baeminPassword"
										type="password"
										class="input-box full-width"
										:rules="[rules.max(item, 'baeminPassword', 25)]"
										placeholder="배달의 민족 비밀번호를 입력하세요"
									/>
								</td>
							</tr>
							<tr>
								<th>요기요 아이디</th>
								<td>
									<input
										v-model="item.yogiyoId"
										class="input-box full-width"
										:rules="[rules.max(item, 'yogiyoId', 25)]"
										placeholder="요기요 아이디를 입력하세요"
									/>
								</td>
								<th>요기요  비밀번호</th>
								<td>
									<input
										v-model="item.yogiyoPassword"
										type="password"
										class="input-box full-width"
										:rules="[rules.max(item, 'yogiyoPassword', 25)]"
										placeholder="요기요 비밀번호를 입력하세요"
									/>
								</td>
							</tr>
							</tbody>
						</table>
					</div>
				</div>
				<div class="flex-1 bg-white">

					<div class="pa-10">

						<h6 class="under-line-identify">사업자 정보</h6>
						<table class="table th-left td-left">
							<col width="120px">
							<col width="width-50-120">
							<col width="120px">
							<col width="width-50-120">
							<tbody>
							<tr>
								<th>사업자 구분 <span class="color-red">*</span></th>
								<td colspan="3">
									<label
										class="cont-radio"
										v-for="code in codes.P02"
										:key="code.TOTAL_CODE"
									>
										<input
											v-model="item.businessType"
											type="radio"
											:value="code.TOTAL_CODE"
											class="box"
										/>
										{{ code.code_name }}
									</label>
								</td>
							</tr>
							<tr
								v-if="item.businessType == '1'"
							>
								<th>사업자등록번호 <span class="color-red">*</span></th>
								<td colspan="3">
									<input
										type="number"
										v-model="item.businessNumber"
										:ruels="[rules.max(item, 'businessNumber', 15)]"
										class="input-box-inline"
										placeholder="사업자 등록 번호"
									/>
								</td>
							</tr>
							<tr
								v-if="item.businessType == '0'"
							>
								<th>사업자등록번호 <span class="color-red">*</span></th>
								<td>
									<input
										type="number"
										v-model="item.businessNumber"
										:ruels="[rules.max(item, 'businessNumber', 15)]"
										class="input-box"
										placeholder="사업자 등록 번호"
									/>
								</td>
								<th>법인등록번호 <span class="color-red">*</span></th>
								<td>
									<input
										type="number"
										v-model="item.corporationNumber"
										:ruels="[rules.max(item, 'corporationNumber', 15)]"
										class="input-box"
										placeholder="법인 등록 번호"
									/>
								</td>
							</tr>
							<tr
								v-if="item.businessType == '2'"
							>
								<th>주민등록 번호 <span class="color-red">*</span></th>
								<td colspan="3">
									<input
										v-model="item.frontSSN"
										type="number"
										:ruels="[rules.max(item, 'frontSSN', 6)]"
										class="input-box-inline mr-10"
										placeholder="주민등록 앞번호"
									/>
									<input
										v-model="item.backSSN"
										type="password"
										maxlength="7"
										class="input-box-inline"
										placeholder="주민등록 뒷번호"
									/>
								</td>
							</tr>
							<tr>
								<th>상호명 <span class="color-red">*</span></th>
								<td colspan="3">
									<input
										type="text"
										v-model="item.shopName"
										maxlength="50"
										class="input-box"
										placeholder="상호명을 입력하세요"
									/>
								</td>
							</tr>
							<tr>
								<th>유선 전화</th>
								<td>
									<input
										type="number"
										v-model="item.linePhone"
										:ruels="[rules.max(item, 'linePhone', 12)]"
										class="input-box full-width"
										placeholder="유선전화번호를 입력하세요"
									/>
								</td>
								<th>팩스 번호</th>
								<td>
									<input
										type="number"
										v-model="item.fax"
										:ruels="[rules.max(item, 'fax', 12)]"
										class="input-box full-width"
										placeholder="팩스번호를 입력하세요"
									/>
								</td>
							</tr>
							<tr>
								<th>사업장 주소 <span class="color-red">*</span></th>
								<td colspan="3">

									<input
										v-model="item.zipCode"

										class="input-box-inline mr-10"
										type="text" placeholder="우편번호"
										readonly

										@click="daumPost('company')"
									>

									<button
										class="box pa-5-10 bg-identify"
										@click="daumPost('company')"
									>주소 검색</button>

									<input
										v-model="item.address"

										class="input-box full-width mt-10" type="text" placeholder="주소"
										readonly

										@click="daumPost('company')"
									>
									<input
										v-model="item.addressDetail"

										class="input-box full-width mt-10" type="text" placeholder="상세주소를 입력하세요."
										maxlength="50"
									>
								</td>
							</tr>
							</tbody>
						</table>

					</div>
				</div>
			</div>

			<div class="mt-10 justify-space-between">
				<div class="flex-1 mr-10 ">
					<div class="pa-10 bg-white">
						<h6>정산 계좌 정보</h6>
						<table class="table th-left td-left top-line-identify">
							<col width="120px" />
							<col width="width-50-120" />
							<col width="120px" />
							<col width="width-50-120" />
							<tbody>
							<tr>
								<th>은행  <span class="color-red">*</span></th>
								<td>
									<select
										v-model="item.calculateBankIdx"
										class="input-box"
									>
										<option value="">선택하세요</option>
										<option
											v-for="(code, index) in codes.P13"
											:key="'bank_' + index"
											:value="code.idx"
										>{{ code.name}}</option>
									</select>
								</td>
								<th>계좌번호 <span class="color-red">*</span></th>
								<td>
									<input
										v-model="item.calculateAccount"
										type="number"
										class="input-box full-width"
										:rules="[rules.max(item, 'account', 25)]"
										placeholder="정산 계좌 번호"
									/>
								</td>
							</tr>
							<tr>
								<th>예금주 <span class="color-red">*</span></th>
								<td>
									<input
										v-model="item.calculateAccountHolder"
										class="input-box-inline mr-10"
										placeholder="정산 계좌 예금주"
										maxlength="15"
									/>
								</td>
								<th>이메일 <span class="color-red">*</span></th>
								<td>
									<input
										type="text"
										v-model="item.email"
										maxlength="50"
										class="input-box"
										placeholder="세금계산서 발행 이메일을 입력하세요"
									/>
								</td>
							</tr>
							</tbody>
						</table>
					</div>
					<div
						v-if="false"
						class="mt-10 bg-white pa-10"
					>
						<h6>배대사 계좌 정보</h6>
						<table class="table th-left td-left top-line-identify">
							<col width="120px" />
							<col width="width-50-120" />
							<col width="120px" />
							<col width="width-50-120" />
							<tbody>
							<tr>
								<th>은행  <span class="color-red">*</span></th>
								<td>
									<select
										v-model="item.bankIdx"
										class="input-box"
									>
										<option value="">선택하세요</option>
										<option
											v-for="(code, index) in codes.P13"
											:key="'bank_' + index"
											:value="code.idx"
										>{{ code.name}}</option>
									</select>
								</td>
								<th>계좌번호 <span class="color-red">*</span></th>
								<td>
									<input
										v-model="item.account"
										type="number"
										class="input-box full-width"
										:rules="[rules.max(item, 'account', 25)]"
										placeholder="배대사 계좌 번호"
									/>
								</td>
							</tr>
							<tr>
								<th>예금주 <span class="color-red">*</span></th>
								<td colspan="3">
									<input
										v-model="item.accountHolder"
										class="input-box-inline mr-10"
										placeholder="배대사 계좌 예금주"
										maxlength="15"
									/>

									<button
										class="bg-identify pa-5-10"
										@click="isHolder"
									>예금주 조회</button>
								</td>
							</tr>
							</tbody>
						</table>
					</div>
				</div>
				<div class="flex-1 bg-white">

					<div class=" pa-10 ">
						<h6>첨부파일</h6>
						<table class="table th-left td-left full-width top-line-identify">
							<col width="120px" />
							<col width="auto" />
							<tbody>
							<tr>
								<th>계약서류</th>
								<td>
									<div
										v-for="(file, index) in codes.merchant_files"
										:key="'file_' + index"

										class=" ptb-10"
									>
										<label class="box pa-5-10 mr-10 vertical-middle">
											<input
												v-show="false"
												type="file"
												accept="image/*"
												@change="setFile($event, file.code)"
											/>
											<v-icon
												small
											>mdi mdi-image-multiple</v-icon> {{  file.name }}
										</label>

										<button
											v-if="item_file[file.code]"
											:key="'file_delete_' + file.code"

											class="btn-default pa-5-10 vertical-middle"
											@click="download(item_file[file.code])"
										>{{ item_file[file.code].originFileName }} <v-icon small>mdi mdi-download</v-icon></button>
										<button
											v-if="false"
											:key="'file_delete_' + index"
											@click="isDeleteFile('', index)"
											class="box pa-5 "
										><v-icon class="color-red" small>mdi mdi-close</v-icon></button>
									</div>

									<div class="mt-10">
										<label class="box pa-5-10 vertical-middle">
											<input
												v-show="false"
												type="file"
												multiple
												accept="image/*"
												@change="setFile($event, 'attachment')"
											/>
											<v-icon
												small
											>mdi mdi-image-multiple</v-icon> 첨부파일
										</label>
									</div>

									<div class="mt-10">
										<template
											v-for="(file, index) in files"
										>
											<button
												:key="'file_' + index"
												class="btn-default pa-5-10 mb-10"
												@click="download(file)"
											>{{ file.originFileName }} <v-icon small>mdi mdi-download</v-icon></button>
											<button
												:key="'file_delete_' + index"
												@click="isDeleteFile('', index)"
												class="box pa-5  mb-10 mr-10"
											><v-icon class="color-red" small>mdi mdi-close</v-icon></button>
										</template>

										<button
											v-for="(file, index) in item.attachments"
											:key="'file_delete_' + index"
											@click="isDeleteFile('attachment', index)"
											class="btn-default pa-5-10 mr-10 mb-10"
										>{{ file.name }} <v-icon class="color-red" small>mdi mdi-close</v-icon></button>
									</div>
								</td>
							</tr>
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</div>

		<div
			class="mtb-30 justify-center"
		>
            <button
                v-if="is_confirm_return"
                class="bg-identify pa-10-20 mr-10"
                @click="isConfirmReturn"
            >심사반려</button>
			<button
				v-if="is_confirm"
				class="bg-identify pa-10-20 mr-10"
				@click="isConfirm"
			>심사완료</button>
			<button
				v-if="is_temp"
				class="bg-identify-outline pa-10-20 mr-10"
				@click="tempSave"
			>임시 저장</button>
			<button
				v-if="is_save"
				class="bg-identify-outline pa-10-20 mr-10"
				@click="doConfirm"
			>저장</button>
			<button
				class="bg-ddd pa-10-20"
				@click="toList"
			>목록</button>
		</div>

		<Modal
			:is_modal="is_modal"
			:option="modal_option"

			title="임시 비밀번호 발급"
			content="임시 비밀번호를 발급하고 등록된 연락처로 전송하시겠습니까?"
			:top="true"
			:bottom="true"
			width="360px"
			content_class="pa-30-10 text-left"

			@close="clear"
			@click="doPassword"
			@cancel="clear"
		>
		</Modal>
		<Modal
			:is_modal="is_modal2"
			:option="modal_option2"
			:top="true"
			:bottom="true"

			title="가맹점 계정상태 변경"
			width="360px"

			@close="clear"
			@click="doStatus"
			@cancel="clear"
		>

			<div
				slot="modal-content"
			>
				<select
					v-model="item.state"
					class="full-width pa-10"
				>
					<option
						v-for="(status, index) in codes.status_agency"
						:key="'status_' + index"
						:value="status.code"
					>{{ status.name }}</option>
				</select>

				<textarea
					class="mt-10"
					v-model="item.reason"
					placeholder="메모"
				></textarea>

			</div>
		</Modal>

		<Modal
			:is_modal="is_modal3"
			:option="modal_option3"
			:top="true"
			:bottom="true"

			title="첨부파일 삭제"
			content="해당 첨부파일을 삭제하시겠습니까?"
			width="360px"

			@close="clear"
			@click="doFileDelete"
			@cancel="clear"
		>
		</Modal>

        <Modal
            :is_modal="is_modal7"
            :option="modal_option4"
            content_class="ptb-30"

            title="심사반려"
            :top="true"
            :bottom="true"
            content="해당 가맹점 등록심사를 심사반려로 처리하시겠습니까?"
            width="360px"

            @close="clear"
            @click="doConfirmReturn"
            @cancel="clear"
        >
        </Modal>

		<Modal
			:is_modal="is_modal4"
			:option="modal_option4"
			content_class="ptb-30"

			title="심사완료"
			:top="true"
			:bottom="true"
			content="가맹점 등록심사에 필요한 항목을 모두 확인하고 심사완료로 처리하시겠습니까?"
			width="360px"

			@close="clear"
			@click="doConfirm"
			@cancel="clear"
		>
		</Modal>

		<Modal
			:is_modal="is_modal5"
			:option="{}"
			:top="true"
			:bottom="false"

			title="첨부파일"

			@close="clear"
		>
			<div slot="modal-content">
        <img :src="item_att" style="object-fit: contain; width: 420px; height: 820px;" alt="" />
			</div>
		</Modal>

		<Modal
			:is_modal="is_modal6"
			:option="{}"
			:top="true"
			title="소속 영업점 변경"

			@close="clear"
			height="850px"
		>
			<AgencySearchList
				slot="modal-content"

				:item_type="99"
				:items="agency_list"
				:user="user"

				@click="setUpper"
				@cancel="upperIdx = item.upperIdx; clear()"
				@next="nextUpper"
			></AgencySearchList>
		</Modal>

		<DaumPost
			:overlay="is_post"
			:config="daum_config"
			@callBack="addPost"
		></DaumPost>
	</div>
</template>

<script>
import Modal from "@/components/Modal";
import DaumPost from "@/components/Daum/DaumPost";
import AgencySearchList from "@/view/Agency/AgencySearchList";
export default {
	name: 'AgencyDetail'
	,
	components: {AgencySearchList, DaumPost, Modal},
	props: ['Axios', 'user', 'codes', 'rules']
	,data: function(){
		return {
			program: {
				name: '가맹점 상세정보'
				, top: true
				, title: true
				, bottom: false
			}
			,fee_base: 4
			,item: {
			}
			,item_origin: {

			}
			,files: []
			,is_modal: false
			,modal_option: {

			}
			,is_modal2: false
			,modal_option2: {
			}
			,is_modal3: false
			,modal_option3: {

			}
			,is_modal4: false
			,is_modal5: false
			,is_modal6: false
			,is_modal7: false
			,modal_option4: {

			}
			,is_post: false
			,daum_config: {
				width: '360px'
			}
			,deleteIdx: []
			,item_att: ''
			,item_file: {
				bossIdImg: {}
				,businessImg: {}
				,copyOfBankbook: {}
			}
			,agency_list: []
			,upperIdx: ''
			,fee2:0
			,maxAvailableFee: 0
		}
	}
	,computed: {
		upper_name: function(){
			let name = this.item.branchName
			if(this.item.distributorIdx){
				name += ' > ' + this.item.distributorName
			}
			if(this.item.agencyIdx){
				name += ' > ' + this.item.agencyName
			}
			if(this.item.resellerIdx){
				name += ' > ' + this.item.resellerName
			}

			return name
		}
		,state_name: function(){
			let state = {
				confirm: { name: '-', color: ''}
				,nice: { name: '-', color: ''}
				,state: { name: '-', color: ''}
			}
			for(let i = 0; i < this.codes.shop_state.length; i++){
				if(this.item.state == this.codes.shop_state[i].code){
					state.state.name = this.codes.shop_state[i].name
					state.state.color = this.codes.shop_state[i].color
					break
				}
			}

			for(let i = 0; i < this.codes.shop_confirm.length; i++){
				if(this.item.confirm == this.codes.shop_confirm[i].code){
					state.confirm.name = this.codes.shop_confirm[i].name
					state.confirm.color = this.codes.shop_confirm[i].color
					break
				}
			}

			for(let i = 0; i < this.codes.nice_confirm.length; i++){
				if(this.item.nice == this.codes.nice_confirm[i].code){
					state.nice.name = this.codes.nice_confirm[i].name
					state.nice.color = this.codes.nice_confirm[i].color
					break
				}
			}

			return state
		}
		, is_temp: function(){
			let is = false
			if(!this.item.confirm && this.user.roleCode == '0'){
				is = true
			}
			return is
		}
		,is_confirm: function(){
			let is = false

			if(!this.item.confirm && this.user.roleCode == '0'){
				is = true
			}

			return is
		}
        ,is_confirm_return: function(){
			let is = false

			if(!this.item.confirm && this.user.roleCode == '0'){
				is = true
			}

			return is
		}
		,is_save: function(){
			let is = false

			if(this.item.confirm && this.user.roleCode == '0'){
				is = true
			}

			return is
		}
	}
	, methods: {
		getData: async function(){
			try{
				this.$emit('onLoading')
				const result = await this.Axios({
					method: 'get'
					,url: 'shop/' + this.$route.params.idx
				})
				if(result.success){
					this.item = result.data
					this.files = this.item.attachments
					this.item_file.businessImg = this.item.businessImg
					this.item_file.bossIdImg = this.item.bossIdImg
					this.item_file.copyOfBankbook = this.item.copyOfBankbook
					this.$delete(this.item, 'businessImg')
					this.$delete(this.item, 'bossIdImg')
					this.$delete(this.item, 'copyOfBankbook')
					this.item.attachments = []
					this.upperIdx = this.item.upperIdx
					if(!this.item.fee) {
						this.$set(this.item, 'fee', 0)
						this.$set(this.item, 'fee2', 0)
					}

					this.item_origin = result.data
				}else{
					this.$emit('setNotify', { type: 'error', message: this.$language.common.error})
				}
			}catch(e){
				console.log(e)
			}finally {
				this.$emit('offLoading')
			}
		}
		,tempSave: async function(){

			try{
				this.$emit('onLoading')
				let result = await this.Axios({
					method: 'put'
					, url: 'shop/temporary/' + this.$route.params.idx
					, data: this.item
					, multipart: true
				})
				if(result.success){
					this.$emit('setNotify', { type: 'success', message: result.message})
					await this.getData()
					await this.getFee()
				}else{
					this.$emit('setNotify', { type: 'error', message: result.message})
				}
			}catch(e){
				console.log(e)
			}finally {
				this.clear()
				this.$emit('offLoading')
			}
		}
		,isPassword: function(){
			this.clear()
			this.is_modal = true
		}
		,isStatus: function(){
			this.clear()
			this.is_modal2 = true
		}
		,clear: function(){
			this.is_modal = false
			this.is_modal2 = false
			this.is_modal3 = false
			this.is_modal4 = false
			this.is_modal5 = false
			this.is_modal6 = false
			this.is_modal7 = false
		}
		,doStatus:  async function(){
			try{
				this.$emit('onLoading')
				const result = await this.Axios({
					method: 'put'
					,url: 'shop/state/' + this.$route.params.idx
					,data: {
						state: this.item.state
						,reason: this.item.reason
					}
				})
				if(result.success){
					await this.getData()
					this.$emit('setNotify', { type: 'success', message: result.message})
				}else{
					this.$emit('setNotify', { type: 'error', message: this.$language.common.error})
				}
			}catch(e){
				console.log(e)
			}finally {
				this.$emit('offLoading')
			}
		}
		,doPassword: async function(){
			try{
				this.$emit('onLoading')
				const result = await this.Axios({
					method: 'put'
					,url: 'shop/tempPassword/' + this.$route.params.idx
				})
				if(result.success){
					this.$emit('setNotify', { type: 'error', message: result.message})
				}else{
					this.$emit('setNotify', { type: 'error', message: this.$language.common.error})
				}
			}catch(e){
				console.log(e)
			}finally {
				this.clear()
				this.$emit('offLoading')
			}
		}
		,toList: function(){
			//this.$router.back()
			this.$emit('push', { name: 'MerchantList', not_query: true})
		}

		,daumPost: function (type) {
			this.address_type = type
			this.is_post = true
		}
		, addPost: function (call) {

			if(this.address_type == 'home'){
				this.$set(this.item, 'homeZipCode', call.zonecode)
				this.$set(this.item, 'homeAddress', call.address)
			}else{
				this.$set(this.item, 'zipCode', call.zonecode)
				this.$set(this.item, 'address', call.address)
			}

			this.is_post = false
		}
		,isDeleteFile: function(type, index){

			if(type == 'attachment'){
				this.$delete(this.item.attachments, index)
			}else{
				this.deleteIdx.push(this.files[index].idx)
				this.$set(this.item, 'deleteIdx', this.deleteIdx)
				this.$delete(this.files, index)
			}
		}
		,doFileDelete: async function(){
			try{
				this.$emit('onLoading')
				const result = await this.Axios({
					method: 'post'
					,url: ''
					,data: this.item
				})
				if(result.success){
					this.$emit('setNotify', { type: 'error', message: result.message})
				}else{
					this.$emit('setNotify', { type: 'error', message: this.$language.common.error})
				}
			}catch(e){
				console.log(e)
			}finally {
				this.clear()
				this.$emit('offLoading')
			}
		}
		,download: function(file){
			this.is_modal5 = true
			this.item_att = file.path
		}
		,getAgencyList: async function(){
			this.is_modal6 = true
		}
		,isHolder: async function(){
			try{
				this.$emit('onLoading')
				const result = await this.Axios({
					method: 'get'
					,url: 'shop/checkAccount'
					,data: {
						accountNumber: this.item.account
						, idx: this.item.bankIdx
						, name: this.item.accountHolder
					}
				})
				if(result.success){
					this.$emit('setNotify', { type: 'success', message: result.message})
				}else{
					this.$emit('setNotify', { type: 'error', message: result.message})
				}
			}catch(e){
				console.log(e)
			}finally {
				this.$emit('offLoading')
			}
		}
		,isConfirm: function(){
			this.is_modal4 = true
		}
        ,isConfirmReturn: function(){
			this.is_modal7 = true
		}
        ,doConfirmReturn: async  function(){
            try{
                this.$emit('onLoading')


                if(this.item.businessType == this.codes.P02_0){
                    this.item.frontSSN = ''
                    this.item.backSSN = ''
                }else if(this.item.businessType == this.codes.P02_1) {
                    this.item.corporationNumber = ''
                    this.item.frontSSN = ''
                    this.item.backSSN = ''
                }else if(this.item.businessType == this.codes.P02_2){
                    this.item.businessNumber = ''
                    this.item.corporationNumber = ''
                }

                const result = await this.Axios({
                    method: 'delete'
                    ,url: 'shop/' + this.$route.params.idx
                    ,data: this.item
                    ,multipart: true
                })
                if(result.success){
                    this.$emit('setNotify', { type: 'success', message: result.message})
                    this.getData()
                }else{
                    if(result.message){
                        this.$emit('setNotify', { type: 'error', message: result.message })
                    }else{
                        this.$emit('setNotify', { type: 'error', message: this.$language.common.error })
                    }
                }
            }catch(e){
                console.log(e)
            }finally {
                this.clear()
                this.$emit('offLoading')
            }
        }
		,doConfirm: async  function(){
			try{
				this.$emit('onLoading')


				if(this.item.businessType == this.codes.P02_0){
					this.item.frontSSN = ''
					this.item.backSSN = ''
				}else if(this.item.businessType == this.codes.P02_1) {
					this.item.corporationNumber = ''
					this.item.frontSSN = ''
					this.item.backSSN = ''
				}else if(this.item.businessType == this.codes.P02_2){
					this.item.businessNumber = ''
					this.item.corporationNumber = ''
				}

				const result = await this.Axios({
					method: 'put'
					,url: 'shop/' + this.$route.params.idx
					,data: this.item
					,multipart: true
				})
				if(result.success){
					this.$emit('setNotify', { type: 'success', message: result.message})
                    this.getData()
				}else{
					if(result.message){
						this.$emit('setNotify', { type: 'error', message: result.message })
					}else{
						this.$emit('setNotify', { type: 'error', message: this.$language.common.error })
					}
				}
			}catch(e){
				console.log(e)
			}finally {
				this.clear()
				this.$emit('offLoading')
			}
		}
		,setFile: function(e, type) {

			if(type == 'attachment'){
				for (let i = 0; i < e.target.files.length; i++) {
					this.item.attachments.push(e.target.files[i])
				}
			}else{
				for (let i = 0; i < this.codes.merchant_files.length; i++) {
					if(type == this.codes.merchant_files[i].code) {
						this.$set(this.item, this.codes.merchant_files[i].code, e.target.files[0])
					}
				}
			}
		}

		, setUpper: function(item, item_list){

			this.item.branchIdx = item_list.branchList.idx
			this.item.upper_name = item_list.branchList.name
			this.item.distributorIdx = ''
			this.item.agencyIdx = ''
			this.item.resellerIdx = ''
			this.item.subresellerIdx = ''

			if(item.type >= item_list.distributorList.type) {
				this.item.distributorIdx = item_list.distributorList.idx
				this.item.upper_name += ' > ' + item_list.distributorList.name
			}

			if(item.type >= item_list.agencyList.type) {
				this.item.agencyIdx = item_list.agencyList.idx
				this.item.upper_name += ' > ' + item_list.agencyList.name
			}

			if(item.type >= item_list.resellerList.type) {
				this.item.resellerIdx = item_list.resellerList.idx
				this.item.upper_name += ' > ' + item_list.resellerList.name
			}

			if(item.type >= item_list.subResellerList.type) {
				this.item.subresellerIdx = item_list.subResellerList.idx
				this.item.upper_name += ' > ' + item_list.subResellerList.name
			}

			this.upperIdx = item.idx
			this.clear()
		}
		,resetUpper: function(){
			this.upperIdx = ''
			this.item.upper_name = ''
		}
		,nextUpper: function(type, sub){
			this.upperIdx = sub.idx
			this.getAgencyList(type)
		}
		,getFee: async function(){
			try{
				this.$emit('onLoading')
				const result = await this.Axios({
					method: 'get'
					,url: 'shop/fee'
					,data: {
						upperAdminIdx: this.upperIdx
					}
				})
				if(result.success){
					this.fee2 = result.data.upperFee
					this.maxAvailableFee = result.data.maxAvailableFee
					// this.$set(this.item, 'fee2', this.fee2 - this.item.fee)
					// this.$set(this.item, 'fee3', result.data.maxAvailableFee)
				}else{
					this.$emit('setNotify', { type: 'error', message: result.message})
				}
			}catch(e){
				console.log(e)
			}finally {
				this.$emit('offLoading')
			}
		}
		,checkFee: function(){
			if(this.maxAvailableFee < this.item.fee){
				this.$emit('setNotify', { type: 'error', message: '수수료할인률은 영업단 수익보다 높을수 없습니다'})
				this.item.fee = 0
				// this.item.fee2 = this.fee2
			}else{
				// this.item.fee2 = this.fee2 - this.item.fee
			}
		}
	}
	, created() {
		this.$emit('onLoad', this.program)
		this.getData()
	}
	,watch: {
		upperIdx: {
			handler: function(call){
				if(call) {
					this.getFee()
				}
			}
		}
	}
}
</script>

<style>
.width-fee { width: 60px !important; text-align: right;}
</style>